import styled from 'styled-components';

import Button from 'calcite-react/Button';

const StyledLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  justify-content: center;
`;

const StyledLinkButton = styled(Button)``;

export { StyledLinksContainer, StyledLinkButton };
